import React from "react";
import { useIntl } from "gatsby-plugin-intl";

import { Layout } from "../components/layout";
import { SEO } from "../components/seo";

type PageProps = {
	path: string,
};

export const ArticlePage: React.FC<PageProps> = ({ path }) => {
  const intl = useIntl();
  const { locale } = intl;

	const title = [] as string[];
	title.push(intl.formatMessage({id: 'A Tale of Two Protests' }));
	title.push(intl.formatMessage({id: 'Article'}));

  return (
    <Layout path={path} nav={false}>
      <SEO title={title.join(' – ')} />
      <Article />
    </Layout>
  );
}

const CompareLeft: React.FC = ({children}) => (<>
	<div className="compare-triangle compare-left compare-triangle-shadow" />
	<div className="compare-triangle compare-left" />
	<div className="compare-dot compare-left" />
	<div className="compare-left-item">
		{children}
	</div>
</>);

const CompareRight: React.FC = ({children}) => (<>
	<div className="compare-triangle compare-right compare-triangle-shadow" />
	<div className="compare-triangle compare-right" />
	<div className="compare-dot compare-right" />
	<div className="compare-right-item">
		{children}
	</div>
</>);

const Article: React.FC = () => {
  const intl = useIntl();
	const timestamp = "2022-02-20";
	const {locale} = intl;

	return (<>
	  <div className="post" id="post">
	    <div className="post-image">
	      <img src="/a-tale-of-two-protests/cover.png" alt='' />
	    </div>
			<h2>{intl.formatMessage({id: 'A Tale of Two Protests' })}</h2>
			<time dateTime={timestamp}>{timestamp}</time>
	    <div className="post-text">

			{(locale === 'fr') ? (<>
				<p><i>Désolé, ce texte n'est pas encore disponible en français.</i></p>
			</>) : null}

			{(locale === 'en' || locale === 'fr') ? (<>
				<p>On January 23rd, Brussels hosted a <b>large pan-european demonstration</b>, one of the largest seen in recent years.</p>
			
				<p>It was a remarkable event, not just because of the enormity of the crowd that showed up, but because there was a separate, <b>second protest</b> that day. This second protest existed mainly in the <b>fever dreams of social commentators and hostile press</b>.</p>

				<p>Let's compare.</p>

				<div class="down-down"></div>
			</>) : null}
			
			{locale === 'nl' ? (<>
				<p>Op 23 januari vond er in Brussel een <b>grote pan-europese demonstratie</b> plaats, één van de grootste in recente jaren.</p>
			
				<p>Het was een merkwaardig event, niet alleen vanwege de enorme menigte die kwam opdagen, maar omdat er een aparte, <b>tweede betoging</b> was die dag. Die tweede betoging bestond vooral in de <b>koortsdromen van sociale commentators en een vijandige pers</b>.</p>
				
				<p>Laten we vergelijken.</p>
				
				<div class="down-down"></div>
			</>) : null}

	    </div>
	  </div>

	  <div className="compare">
		
			<div className="compare-stripe compare-stripe-left"></div>
			<div className="compare-stripe compare-stripe-right"></div>
			<div className="narrative-dot compare-left"></div>
			<div className="narrative-dot compare-right"></div>

			<div className="compare-box">
	      <img src="/a-tale-of-two-protests/petit-ceinture.jpg" alt='' />
			</div>

      <CompareLeft>

				{(locale === 'en' || locale === 'fr') ? (<>
					<p><b>The protest kicks off</b> at the Gare du Nord train station. Given the large anticipated crowd, it starts earlier than usual. Protesters begin their pre-approved march along the Brussels ring road, towards the European quarter. The march is in good spirits, with plenty of flags, music, drums and costumes.</p>

					<p><b>Old and young are both represented</b>, and the crowd includes firefighters and health care workers. Designated <b>veteran volunteers help with crowd control</b>.</p>
				
					<p>The march arrives at Parc Cinquantenaire <b>without incident</b>. When the first group arrives at the destination, people are still joining the march at the tail, supporting the claims of a massive crowd.</p>
				</>) : null}

				{locale === 'nl' ? (<>
					<p><b>De betoging gaat van start</b> aan het Noordstation. Gezien de verwachtte aantallen, begint deze vroeger dan gewoonlijk. De betogers vangen hun vooraf toegelaten mars aan en stappen langs de kleine ring, richting de Europese wijk. De goede sfeer zit er in, met veel vlaggen, muziek, trommels en kostuums.</p>

					<p><b>Jong en oud zijn aanwezig</b>, en er lopen ook brandweerlui en verpleegkundigen mee. Een groep <b>vrijwillige veteranen helpt mee met crowd control</b>.</p>
				
					<p>De mars komt aan in het Jubelpark <b>zonder incidenten</b>. Wanneer de kop de bestemming bereikt, zijn er nog steeds mensen die de staart vervoegen, een bewijs van de claim van het grote aantal aanwezigen.</p>
				</>) : null}

			</CompareLeft>

			<div className="compare-box">
	      <img src="/a-tale-of-two-protests/stewards.jpg" alt='' />
			</div>

			<CompareRight>

				{(locale === 'en' || locale === 'fr') ? (<>
					<p><a href="https://archive.fo/ePt49" target="_blank">Anti-vax protesters</a> have once again gathered in Brussels. Despite the fact that the science is settled, many of these vaccine-refusers insist on spreading <b>disinformation and dissent</b>. Tensions are high on this day. Riot police have been mobilized and are out in full force.</p>

					<p>Tourists and travelers are advised to <b>avoid the city centre</b>, as an uncontrolled <a href="https://archive.fo/JxDKl" target="_blank">march of destruction</a> plows through the city. No bus stop or storefront is safe. Particularly worrisome are the <b>right-wing extremists</b> leading this march, clearly pictured.</p>
				</>) : null}
					
				{locale === 'nl' ? (<>
					<p><a href="https://archive.fo/ePt49" target="_blank">Antivaxbetogers</a> zijn nogmaals in Brussel samengekomen. Hoewel de wetenschap duidelijk is, verspreiden velen van deze vaccinweigeraars toch <b>desinformatie en onenigheid</b>. De spanning staat hoog vandaag. Oproerpolitie werd gemobiliseerd en zijn dan ook massaal aanwezig.</p>

					<p>Toeristen en reizigers wordt aangeraden om <b>het stadscentrum te vermijden</b>, omdat een oncontroleerbare <a href="https://archive.fo/JxDKl" target="_blank">stoet van vernieling</a> door de stad trekt. Geen bushokje of winkelraam is veilig. Het is vooral zorgwekkend dat de mars <b>geleid wordt door rechtsextremisten</b>, duidelijk zichtbaar in beeld.</p>
				</>) : null}
					
			</CompareRight>

			<div className="compare-box">
	      <img src="/a-tale-of-two-protests/screens.jpg" alt='' />
			</div>

			<CompareLeft>

				{(locale === 'en' || locale === 'fr') ? (<>

					<p>At the park, protesters are greeted with a <b>highly professional event</b>. Thanks to the organization by Europeans United, several screens are available so the crowd can follow the speeches. Estimates say <b>at least 100,000</b>, likely even more, as people continue to come and go the entire time, and the crowd is much larger than previous events. Several food stands provide sustenance and refreshments, in addition to the ever-present waffle trucks.</p>

					<p>The line-up is diverse, with both <b>local and international speakers</b>. This includes not just activists and organizers from countries such as Austria and Italy, and a European Member of Parliament, but also Vera Sharav, a <b>Jewish holocaust survivor</b>.</p>

				</>) : null}

				{locale === 'nl' ? (<>

					<p>In het park worden de betogers onthaald met een <b>zeer professioneel event</b>. Dankzij organisatie Europeans United staan er verschillende grote schermen om de speeches op te volgen. Schattingen zeggen dat er <b>minstens 100 000</b> man aanwezig is, misschien wel meer, omdat mensen voordurend komen en gaan, en de massa duidelijk veel groter is dan alle vorige betogingen. Verschillende standjes voorzien de betogers van drank en spijs, net als de gewoonlijke wafelkarren.</p>
					
					<p>Het programma is divers, met zowel <b>lokale als internationale sprekers</b>. Dit omvat niet alleen activisten en organisatoren van Oostenrijk en Italië, en een Europees parlementslid, maar ook Vera Sharav, een <b>joodse holocaustoverlevende</b>.</p>

				</>) : null}

			</CompareLeft>

			<div className="compare-box">

				<div className="video-embed">
					<iframe id="odysee-iframe" src="https://odysee.com/$/embed/vera-sharav-2022-01-23/f26884f416393238362f0cb3c25543f30bbae13f?r=DjUmNBvCkeLqQnw7oftxiMo4ue7uVbHb" allowfullscreen></iframe>
				</div>

			</div>
			
			<CompareRight>
			
				{(locale === 'en' || locale === 'fr') ? (<>
			
					<p>Police report <b>about 50,000 attendees</b>, though it is unclear what these protesters want. Their message is <b>muddled and disorganized</b>, and they don't seem to know why they're there in the first place. Many have been radicalized by calls on social media. Most attendees just mill around aimlessly, as if waiting for the inevitable riot.</p>

					<p>The line-up features an incoherent mix of <b>science denialism, conspiracy theory, and infantile screaming about freedom</b>. While the organization tries to present a clean image, their extremist ties are well known.</p>

				</>) : null}

				{locale === 'nl' ? (<>
			
					<p>Volgens de politie zijn er <b>ongeveer 50 000 aanwezigen</b>, maar het is niet duidelijk wat deze mensen nu juist willen. Hun boodschap is <b>verward en onoverzichtelijk</b>, en ze weten vaak niet waarom ze eigelijk zijn komen opdagen. Velen zijn door oproepen op sociale media geradicaliseerd. De meeste aanwezigen hangen gewoon doelloos rond, alsof ze op de onvermijdelijke rel aan het wachten zijn.</p>

					<p>Het programma bevat een onsamenhangende mix van <b>wetenschapsontkenners, complottheorie, en kinderachtig geschreeuw over vrijheid</b>. Hoewel de organisatie een proper imago wilt voorleggen, zijn hun extremistische banden welgekend.</p>

				</>) : null}

			</CompareRight>

			<div className="compare-box">

				<div className="video-embed">
					<iframe id="odysee-iframe" src="https://odysee.com/$/embed/brussels-2022-01-23-schuman/60ed83b3f09bf8f462b0b2f551329ed5af806212?r=DjUmNBvCkeLqQnw7oftxiMo4ue7uVbHb" allowfullscreen></iframe>
				</div>

			</div>

			<CompareLeft>

				{(locale === 'en' || locale === 'fr') ? (<>

					<p>While the event <b>continues peacefully</b> in the park, a riot forms by the nearby Schuman-square. Unlike every prior March for Freedom, police have left an EU building unguarded. <b>Masked troublemakers</b> brandishing an <a href="https://odysee.com/@unconed:c/brussels-2022-01-23-schuman-2:9" target="_blank">antifa banner</a> begin to provoke and escalate, and police steps in, but not before the building's lobby is smashed to pieces.</p>

					<p>The police's tactics go from <a href="/a-tale-of-two-protests/2022-01-23 Carine Knapen 4.jpeg" target="_blank">strange to malicious</a>, as the rioters are then surrounded from 3 sides, leaving them no place to go except into the park.</p>
				
					<p>The police does not protect the civilians who are <b>exercising their right to demonstrate</b>, by isolating the troublemakers. Instead they use tear gas and water cannons to <b>push the riot directly towards the main event</b>, several hundred meters farther into the park.</p>
					
				</>) : null}

				{locale === 'nl' ? (<>

					<p>Hoewel het event <b>vreedzaam verloopt</b> in het park, ontstaat er een rel aan het nabijliggende Schumanplein. In tegenstelling tot iedere vorige mars, heeft de politie de doorgang tot een EU-gebouw volledig open gelaten. <b>Gemaskerde relschoppers</b> met een <a href="https://odysee.com/@unconed:c/brussels-2022-01-23-schuman-2:9" target="_blank">antifa-vlag</a> beginnen te provoceren en escaleren, en de politie treedt op, maar niet alvorens de inkom van het gebouw tot puin geslagen wordt.</p>

					<p>De taktiek van de politie gaat hier van <a href="/a-tale-of-two-protests/2022-01-23 Carine Knapen 4.jpeg" target="_blank">vreemd naar kwaadaardig</a>, wanneer ze de relschoppers van 3 kanten omsingelen, zodat de enige uitweg rechtstreeks het park in gaat.</p>
				
					<p>De politie weigert de burgers te beschermen die hun <b>democratisch recht om te betogen uitoefenen</b>, en scheidt de hooligans niet af van de rest. Ze gebruiken net hun waterkanonnen en traangas om <b>de rel rechtstreeks naar het podium te duwen</b>, enkele honderden meters verder in het park.</p>
					
				</>) : null}
					
			</CompareLeft>

			<div className="compare-box">
				<div className="video-embed">
					<video controls src="/a-tale-of-two-protests/jubelpark.mp4" />
				</div>
			</div>

			<CompareRight>

				{(locale === 'en' || locale === 'fr') ? (<>
					<p>As expected, just a few hours in, the protest in Brussels has already devolved into <b>complete chaos</b>. Near the Schuman-square, <b>police have been forced to use tear gas and water cannons</b> in an attempt to control the unruly crowd. One building has been <b>completely smashed</b>, as documented by numerous brave reporters in gas masks.</p>

					<p>It is unlikely that the situation will get better. The mayor has stepped in to <b>take control of the situation</b>, hoping to avert the worst. Stay tuned for more updates directly from the ground.</p>
				</>) : null}

				{locale === 'nl' ? (<>
					<p>Zoals verwacht, enkele uren na de start, is de betoging in Brussel al tot een <b>volledige chaos</b> gedevolueerd. Nabij het Schumanplein moest de <b>politie traangas en waterkanonnen inzetten</b> in een poging om de menigte te bedaren. Eén gebouw werd reeds <b>compleet vernield</b>, wat door verschillende moedige verslaggevers in gasmasker werd opgenomen.</p>

					<p>De situatie gaat waarschijnlijk niet beter worden. De burgemeester heeft persoonlijk <b>controle over de situatie</b> genomen, in de hoop om het ergste te vermijden. Verdere updates ter plaatste volgen zodra.</p>

				</>) : null}

			</CompareRight>

			<div className="compare-box">

				<div className="video-embed">
					<iframe src="https://www.youtube.com/embed/klQP_N-DAyg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</div>

			</div>

			<div className="compare-box">
				<div className="video-embed">
					<video controls src="/a-tale-of-two-protests/criminals.mp4" />
				</div>
			</div>

			<CompareLeft>

				{(locale === 'en' || locale === 'fr') ? (<>
					<p>Both the organization and the attendees can see the <b>growing cloud of tear gas</b> behind them, as <b>organizers call the rioters criminals</b>. After <a href="/a-tale-of-two-protests/2022-01-23 Carine Knapen 2.jpeg" target="_blank">failing to reach an agreement</a> with police, they announce the event is <b>shut down prematurely</b>, much to the dismay of the crowd.</p>
				
					<p>Many choose to remain regardless, beginning a stand-off as the police engage in typical kettling tactics. Some who do try to leave are <b>prevented from doing so</b>, and end up being <a href="https://archive.fo/Y9rTN" target="_blank">detained for hours</a>. Protesters who are waiting for their chartered buses to pick them up are told they need to disperse and leave as well, with nowhere to go.</p>
				</>) : null}

				{locale === 'nl' ? (<>
					<p>Zowel de organisatie als de aanwezigen merken de <b>groeiende wolk van traangas</b> op achter hen, en de <b>organisatie noemt de relschoppers criminelen</b>. Na een <a href="/a-tale-of-two-protests/2022-01-23 Carine Knapen 2.jpeg" target="_blank">gefaalde poging tot overleg met politie</a>, kondigt men aan dat het event <b>vroegtijdig wordt stopgezet</b>, tot grote teleurstelling van de betogers.</p>
				
					<p>Velen kiezen om toch te blijven, en zo begint er een stand-off met de politie, die typische ketel-taktieken beginnen in te zetten. Sommigen die proberen te vertrekken <b>wordt de doorgang belemmerd</b>, en ze worden <a href="https://archive.fo/Y9rTN" target="_blank">enkele uren vastgehouden</a>. Betogers die op hun gecharterde bus staan te wachten wordt ook bevolen om op te splitsen en te vertrekken, hoewel ze nergens naar toe kunnen.</p>
				</>) : null}

			</CompareLeft>

			<div className="compare-box">
				<div className="video-embed">
					<video controls src="/a-tale-of-two-protests/oh-romeo.mp4" />
				</div>
			</div>

			<CompareRight>

				{(locale === 'en' || locale === 'fr') ? (<>
					<p>In Brussels, the <b>chaos has become untenable</b>. The rioters have been <b>ordered to disperse</b> and clear the park, but have refused to do so. The police is now fighting them head-to-head, doing their best to <b>prevent further harm to bystanders and property</b>.</p>

					<p>Many of the initial protesters have already left, leaving only a <b>hard core of fanatics</b> bent on escalating the violence. The mood is now unquestionably grim.</p>
				</>) : null}

				{locale === 'nl' ? (<>
					<p>In Brussel, is de <b>chaos nu onhoudbaar</b>. De relschoppers zijn <b>bevolen om te vertrekken</b> en het park te ontruimen, maar weigeren dit te doen. De politie vecht nu man-op-man, en doen hun best om <b>verdere schade aan omstaanders en eigendom</b> te voorkomen.</p>

					<p>Veel van de oorspronkelijke betogers zijn reeds vertrokken, en er blijft enkel nog een <b>harde kern van fanatici</b> achter, die moedwillig geweld escaleren. De sfeer is nu mateloos grimmig.</p>
				</>) : null}

			</CompareRight>

			<div className="compare-box">
	      <img src="/a-tale-of-two-protests/linie.jpg" alt='' />
			</div>

			<CompareLeft>
			
				{(locale === 'en' || locale === 'fr') ? (<>
			
					<p>Protesters that have remained are now <b>pinned under the arches</b> of the Cinquantenaire, built to commemorate the Belgian revolution. Police are <b>taking pot-shots with water cannons</b> at anyone who tries to leave. The organization is packing up their rented equipment in a hurry, <b>fearing water damage</b>. When the police's pincer manoeuvre comes to a point, protesters decide to face the approaching police line <b>with their hands raised</b>, hoping to rush through and escape unscathed.</p>

					<p>In the margins of the protest, an <a href="https://www.bruzz.be/mobiliteit/man-dient-klacht-wegens-politiegeweld-marge-coronamars-2022-01-25" target="_blank">innocent bystander</a> is <b>beaten to a pulp by riot police</b>. He was coming to meet his girlfriend, who was out grocery shopping and called for help. Another video shows police <b>shooting pepper spray</b> into a chartered bus. In one instance, an officer is seen <b>kicking in a glass front door</b>, to pepper spray people who were taking shelter.</p>

				</>) : null}

				{locale === 'nl' ? (<>

					<p>De achtergebleven betogers staan nu <b>vast onder de triomfbogen</b> van het Jubelpark, die gebouwd werden om de Belgische revolutie te herdenken. De politie <b>schiet willekeurig met hun waterkanonnen</b> op mensen die proberen te vertrekken. De organisatie probeert snel hun gehuurde installatie af te breken, <b>uit vrees voor waterschade</b>. Wanneer het tangmanoeuvre van de politie voltooid wordt, besluiten vele betogers om de linie <b>met de handen omhoog te doorbreken</b>, om zo veilig te kunnen ontsnappen.</p>

					<p>In de marge van de betoging wordt <a href="https://www.bruzz.be/mobiliteit/man-dient-klacht-wegens-politiegeweld-marge-coronamars-2022-01-25" target="_blank">een onschuldige omstaander</a> <b>tot bloedens toe in elkaar geslagen</b>. Hij was opweg naar zijn vriendin, die aan het winkelen was en om hulp gevraagd had. Een andere video toont hoe de politie <b>pepper spray in een gecharterde bus spuit</b>. Ook merkwaardig is de agent die <b>een glazen deur intrapt</b>, om mensen die aan het schuilen waren te besproeien.</p>

				</>) : null}
					
			</CompareLeft>
		
			<div className="compare-box">
				<div className="video-embed">
					<video controls src="/a-tale-of-two-protests/krak.mp4" />
				</div>
			</div>

			<CompareRight>

				{(locale === 'en' || locale === 'fr') ? (<>

					<p>The rioters continue their <b>unhinged violence</b>. A police officer who was taking shelter in a metro station has been <b>brutally assaulted</b> when a fence was thrown down the stairway. The video of this incident is circulating, confirming what many already knew: these protesters have <b>no respect for life or authority</b>. The Minister of Interior tweets that anyone who engages in violent tactics <b>loses their right to sit at the discussion table</b>.</p>

					<p>Luckily the police eventually manage to regain control of the situation. Public services will now begin the arduous task of <b>cleaning up the damage</b> overnight.</p>
					
				</>) : null}
			
				{locale === 'nl' ? (<>

					<p>De relschoppers zijn <b>mateloos gewelddadig</b>. Een agent die onderaan de trap van een metrostation stond te schuilen wordt <b>brutaal aangevallen</b> wanneer er een hek naar hem gegooid wordt. De video hiervan circuleert, wat bevestigt wat velen reeds wisten: deze betogers hebben <b>geen respect voor anderen, of voor gezag</b>. De Minister van Binnenlandse Zaken tweet dat wie zich met zulk geweld mengt, <b>zijn plaats aan de discussietafel verliest</b>.</p>

					<p>Gelukkig heeft de politie uiteindelijk toch de controle over de situatie opnieuw gevat. De openbare diensten hebben nu de kwalijke taak om zo snel mogelijk <b>het puin op te ruimen</b>.</p>

				</>) : null}
					
			</CompareRight>

		</div>

	  <div className="post" id="post">

			{(locale === 'en' || locale === 'fr') ? (<>

				<p>Depending on who you listen to, this was either a <b>hugely popular event that was sabotaged</b> by overzealous and suspicious police action, or a <b>chaotic but predictable riot</b> from people who don't deserve to be listened to.</p>
				<p>Having been there from start to end, including the pincer, I think only the first story comes close to being true.</p>
				<p>Remarkably, there are <a href="https://deanderekrant.nl/nieuws/politiegeweld-demo-amsterdam-bewuste-actie-2022-01-08" target="_blank">similar reports</a> about the <b>protest in Amsterdam</b> on January 2nd. There, people who were specifically directed to evacuate the event along a particular street were surrounded and attacked, and even assaulted by police dogs. According to eye witnesses, there is no doubt this was a coordinated and planned police action.</p>
				
			</>) : null}

			{locale === 'nl' ? (<>

				<p>Naargelang wie het vertelt, was dit een <b>enorm populair event dat saboteerd werd</b> door een overdadig en verdacht politie-optreden, of een <b>chaotische maar voorspelbare rel</b> van mensen die het niet verdienen dat je naar hen luistert.</p>
				
				<p>Ik was er zelf van begin tot eind, en stond midden in de tang, en volgens mij komt enkel de eerste versie ook maar in de buurt van de waarheid.</p>

				<p>Opmerkelijk is dat er <a href="https://deanderekrant.nl/nieuws/politiegeweld-demo-amsterdam-bewuste-actie-2022-01-08" target="_blank">gelijkaardige verhalen</a> de ronde doen over de <b>betoging in Amsterdam</b> op 2 januari. Daar werd de mensen explicit gevraagd om via een bepaalde route te evacueren; ze werden dan omsingeld en aangevallen, en zelfs met politiehonden gebeten. Volgens ooggetuigen staat het buiten kijf dat dit een gecoördineerde en geplande politie-actie was.</p>
				
			</>) : null}
	
	  </div>
	</>);
};

export default ArticlePage;
